import React, { useEffect, useState, useMemo } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import objectPath from "object-path";

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useHtmlClassService } from "../../../../_metronic/layout/_core/MetronicLayout";
import { LanguageSelectorDropdown } from "../../../../_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown";
import { resetPassword } from "../../../services/authenitcation/resetPassword";

import * as auth from "../_redux/authRedux";
const initialValues = {
  password: "",
  confirm_password: "",
};

function ResetPassword(props) {
  let history = useHistory();
  const { intl } = props;
  const [loading, setLoading] = useState(false);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      asideClassesFromConfig: uiService.getClasses("aside", true),
      extrasLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
    };
  }, [uiService]);

  useEffect(() => {
    if (
      props &&
      props?.match?.params &&
      props?.match?.params?.email &&
      props?.match?.params?.token
    ) {
      props.logout();
    }
  }, [props, props.match.params]);

  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    confirm_password: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          intl.formatMessage({
            id: "AUTH.VALIDATION.CONFIRM_PASSWORD_FIELD",
          })
        ),
      }),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
      let data = {
        password: values.password,
        token: props.match.params.token,
        email: props.match.params.email,
      };

      resetPassword(data)
        .then((res) => {
          console.log(res.data.response_type);
          if (res && res.data.response_type === "success") {
            disableLoading();
            setSubmitting(false);
            history.push("/auth/login");
          } else {
            disableLoading();
            setSubmitting(false);
            setStatus(intl.formatMessage({ id: "RESET_PASSWORD_ERROR" }));
          }
        })
        .catch((e) => {
          console.log(e);
          disableLoading();
          setSubmitting(false);
        });
    },
  });

  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
        id="kt_login"
      >
        <div
          className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10  justify-content-center align-items-center"
          style={{
            backgroundImage: `url(${toAbsoluteUrl(
              "/media/image/banner10.png"
            )})`,
          }}
        >
          <div className="">
            <Link
              to="/"
              onClick={(event) => event.preventDefault()}
              className="pb-lg-0 pb-10"
            >
              <img
                alt="Logo"
                className="max-h-150px"
                src={toAbsoluteUrl("/media/logo/LOGO_MUTUA_GENESI.png")}
              />
            </Link>
          </div>
        </div>
        <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
          <div className="text-right">
            {!layoutProps.extrasLanguagesDisplay && (
              <LanguageSelectorDropdown />
            )}
          </div>
          <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
            <div
              className="login-form login-signin"
              style={{ display: "block" }}
            >
              <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">
                  <FormattedMessage id="RESET_PASSWORD_TITLE" />
                </h3>
              </div>

              <form
                id="kt_login_signin_form"
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                onSubmit={formik.handleSubmit}
              >
                {/* begin: Alert */}
                {formik.status && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">
                      {formik.status}
                    </div>
                  </div>
                )}
                {/* end: Alert */}

                {/* begin: Password */}
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder={props.intl.messages["AUTH.INPUT.PASSWORD"]}
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "password"
                    )}`}
                    name="password"
                    {...formik.getFieldProps("password")}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.password}
                      </div>
                    </div>
                  ) : null}
                </div>
                {/* end: Password */}

                {/* begin: Confirm Password */}
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder={
                      props.intl.messages["AUTH.INPUT.CONFIRM_PASSWORD"]
                    }
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "confirm_password"
                    )}`}
                    name="confirm_password"
                    {...formik.getFieldProps("confirm_password")}
                  />
                  {formik.touched.confirm_password &&
                  formik.errors.confirm_password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.confirm_password}
                      </div>
                    </div>
                  ) : null}
                </div>
                {/* end: Confirm Password */}

                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={formik.isSubmitting}
                    className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                  >
                    <span>
                      <FormattedMessage id="RESET_PASSWORD_TITLE" />
                    </span>
                    {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
