import { axiosGet, axiosPost } from "../../../../redux/setupAxios";
export const LOGIN_URL = "api/auth/login";
export const OTP_URL = "api/auth/otp";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "api/me";

export async function login(email, password) {
  const data = await axiosPost("/login", {
    email,
    password,
  });
  return data;
}

export async function otpVerification(data) {
  return await axiosPost("/otp", data);
}

export async function setPassword(data) {
  return await axiosPost("/set-password", data);
}

export async function requestPassword(data) {
  return await axiosPost("/forgot-password", data);
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axiosGet("/user");
}
