import React from "react";
import { NavLink } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation } from "react-router";
import SVG from "react-inlinesvg";
import { FormattedMessage } from "react-intl";

import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

const SuperAdminNav = (props) => {
  const location = useLocation();

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  // /client/list-signed-policy
  return (
    <div id="kt_header_menu" data-menu-vertical="1">
      <ul className="list-unstyled flex-column" role="tablist">
        <li
          className={`nav-item mb-3 menu-item ${getMenuItemActive(
            "/dashboard"
          )}`}
          aria-haspopup="true"
        >
          <NavLink
            className="menu-link nav-link btn btn-icon btn-clean btn-lg"
            to="/dashboard"
            data-toggle="tab"
            id={`#${props.tabs.tabId1}`}
            role="tab"
            onClick={() => props.handleTabChange(props.tabs.tabId1)}
          >
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="latest-project">
                  <FormattedMessage id="SUPER_ADMIN_DASHBOARD" />
                </Tooltip>
              }
            >
              <div>
                <span className="svg-icon svg-icon-lg">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")} />
                </span>
                <span className="menu-text d-none">
                  <FormattedMessage id="SUPER_ADMIN_DASHBOARD" />
                </span>
              </div>
            </OverlayTrigger>
          </NavLink>
        </li>
        <li
          className={`nav-item mb-3 menu-item ${getMenuItemActive(
            "/super-admin/list-user"
          )}`}
          aria-haspopup="true"
        >
          <NavLink
            className="menu-link nav-link btn btn-icon btn-clean btn-lg"
            to="/super-admin/list-user"
            data-toggle="tab"
            id={`#${props.tabs.tabId3}`}
            role="tab"
            onClick={() => props.handleTabChange(props.tabs.tabId3)}
          >
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="latest-project">
                  <FormattedMessage id="ASIDE_SUPER_ADMIN_USER" />
                </Tooltip>
              }
            >
              <div>
                <span className="svg-icon svg-icon-lg">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                  />
                </span>
                <span className="menu-text d-none">
                  <FormattedMessage id="ASIDE_SUPER_ADMIN_USER" />
                </span>
              </div>
            </OverlayTrigger>
          </NavLink>
        </li>

        {/* for add admin button */}
        <li
          className={`menu-item d-none ${getMenuItemActive(
            "/super-admin/add-admin",
            false
          )}`}
        >
          <NavLink className="menu-link" to="/super-admin/add-admin">
            <span className="menu-text">
              <FormattedMessage id="SUPER_ADMIN_ADD_ADMIN_BUTTON" />
            </span>
          </NavLink>
        </li>

        {/* for current user profile */}
        <li
          className={`menu-item d-none ${getMenuItemActive(
            "/user-profile",
            false
          )}`}
        >
          <NavLink className="menu-link" to="/user-profile">
            <span className="menu-text">
              <FormattedMessage id="USER.PROFILE.TITLE" />
            </span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default SuperAdminNav;
