import React, { useEffect, useState } from "react";

import { injectIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";

import { connect } from "react-redux";
import * as notification from "./_redux/notification";

import { Layout } from "../_metronic/layout";
import BasePage from "../../src/app/BasePage";

import CustomizedSnackbars from "./component/notification";

const MainPage = (props) => {
  const [openNotification, setOpenNotification] = useState(false);

  const notification = useSelector(
    ({ notification }) => notification,
    shallowEqual
  );

  useEffect(() => {
    if (
      notification.toggle &&
      notification.message !== "" &&
      notification.type !== ""
    ) {
      setOpenNotification(true);
    } else {
      setOpenNotification(false);
    }
  }, [notification]);

  function handleClose() {
    props.setErrorNotification({
      message: "",
      toggle: false,
      type: "",
    });
  }

  return (
    <Layout>
      <BasePage />
      <CustomizedSnackbars
        handleClose={handleClose}
        open={openNotification}
        variant={notification.type !== "" ? notification.type : "info"}
        message={notification.message}
      />
    </Layout>
  );
};

export default injectIntl(connect(null, notification.actions)(MainPage));
