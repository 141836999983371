import React from "react";
import { shallowEqual, useSelector } from "react-redux";

const AdminDashboard = React.lazy(() =>
  import("../modules/dashboard/adminDashboard")
);
const ClientDashboard = React.lazy(() =>
  import("../modules/dashboard/clientDashboard")
);
const InformantMemberDashboard = React.lazy(() =>
  import("../modules/dashboard/informantMemberDashboard")
);
const SuperAdminDashboard = React.lazy(() =>
  import("../modules/dashboard/superAdminDashboard")
);

export function DashboardPage() {
  const currentRole = useSelector(
    ({ currentRole }) => currentRole,
    shallowEqual
  );
  return (
    <>
      {currentRole === "admin" ? <AdminDashboard /> : null}
      {currentRole === "client" ? <ClientDashboard /> : null}
      {currentRole === "informant_member" ? <InformantMemberDashboard /> : null}
      {currentRole === "super_admin" ? <SuperAdminDashboard /> : null}
    </>
  );
}
