import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Logout, AuthPage } from "./modules/Auth";

import MainPage from "../../src/app/MainPage";
import ResetPassword from "../app/modules/Auth/pages/ResetPassword";

function Routes(props) {
  const { isAuthorized, isPasswordSet, isToken } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user,
      isPasswordSet: auth.passwordToggle,
      isToken: auth.authToken,
    }),
    shallowEqual
  );

  const currentRole = useSelector(
    ({ currentRole }) => currentRole,
    shallowEqual
  );

  return (
    <Switch>
      <Route
        exact
        path="/auth/reset-password/:token/:email"
        component={ResetPassword}
      />
      {isAuthorized && isToken && isPasswordSet ? (
        <Redirect from="/auth" to="/" />
      ) : (
        <Route>
          <AuthPage />
        </Route>
      )}
      <Route path="/logout" component={Logout} />
      {currentRole && <Route path="/" component={MainPage}></Route>}
    </Switch>
  );
}

export default Routes;
