import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import { shallowEqual, useSelector } from "react-redux";

const UserProfile = React.lazy(() => import("./pages/UserProfile"));

// admin
const ListPolicy = React.lazy(() => import("../app/modules/admin/policy/list"));
const PolicyForm = React.lazy(() => import("../app/modules/admin/policy/form"));
const ViewPolicy = React.lazy(() => import("./component/viewPolicy/view_pdf"));
const ListInformantMember = React.lazy(() =>
  import("../app/modules/admin/informant-member/list")
);
const ListSignedPolicyForAdmin = React.lazy(() =>
  import("../app/modules/admin/policy/SignedPolicyList")
);
const ListCommunication = React.lazy(() =>
  import("../app/modules/admin/communication/list")
);
const CommunicationForm = React.lazy(() =>
  import("../app/modules/admin/communication/form")
);
const CommunicationFile = React.lazy(() =>
  import("../app/component/viewPolicy/view_file")
);

// informant-member
const ListSignedPolicy = React.lazy(() =>
  import("../app/modules/informant-member/signed-policy/list")
);
const SubscribeClientPolicy = React.lazy(() =>
  import("../app/modules/informant-member/client/subscribeClientPolicy")
);
const MainPolicyForm = React.lazy(() =>
  import("../app/modules/informant-member/signed-policy/mainPolicyForm")
);
const policyListForInformantMember = React.lazy(() =>
  import("../app/modules/informant-member/policy/list")
);
const ListInformantMemberForInformantMember = React.lazy(() =>
  import("../app/modules/informant-member/informant-member/list")
);
const FormInformantMemberForInformantMember = React.lazy(() =>
  import("../app/modules/informant-member/informant-member/wizard")
);
const MainInformantForm = React.lazy(() =>
  import("../app/modules/informant-member/informant-member/mainInformantForm")
);
const CompanyList = React.lazy(() =>
  import("../app/modules/informant-member/company/list")
);
const CompanyForm = React.lazy(() =>
  import("../app/modules/informant-member/company/wizard")
);

// client
const ListClientSignedPolicy = React.lazy(() =>
  import("../app/modules/client/signed-policy/list")
);

//super-admin
const ListUserSuperAdmin = React.lazy(() =>
  import("../app/modules/super-admin/user/list")
);
const AdminFormSuperAdmin = React.lazy(() =>
  import("../app/modules/super-admin/user/form")
);

const componentRoute = (routeRole, role, component) => {
  return routeRole === role ? component : DashboardPage;
};

const AdminRoute = () => {
  return (
    <>
      <ContentRoute path="/admin/list-policy" component={ListPolicy} />
      <ContentRoute path="/admin/add-policy" component={PolicyForm} />
      <ContentRoute
        path="/admin/view-policy/:id"
        component={ViewPolicy}
      ></ContentRoute>
      <ContentRoute
        path="/admin/list-informant-member"
        component={ListInformantMember}
      />
      <ContentRoute
        path="/admin/list-signed-policy"
        component={ListSignedPolicyForAdmin}
      />
      {/* communication flow */}
      <ContentRoute
        path="/admin/list-communication"
        component={ListCommunication}
      />
      <ContentRoute
        path="/admin/add-communication"
        component={CommunicationForm}
      />
      <ContentRoute
        path="/admin/view-communication-file/:id"
        component={CommunicationFile}
      />
    </>
  );
};

const InformantRoute = () => {
  return (
    <>
      <ContentRoute
        path="/informant-member/list-policy"
        component={policyListForInformantMember}
      />
      <ContentRoute
        path="/informant-member/view-policy/:id"
        component={ViewPolicy}
      ></ContentRoute>
      <ContentRoute
        path="/informant-member/list-sign-policy"
        component={ListSignedPolicy}
      />
      <ContentRoute
        path="/informant-member/subscribe-policy"
        component={SubscribeClientPolicy}
      />

      <ContentRoute
        path="/informant-member/client-for-policy/:policyId"
        component={MainPolicyForm}
      />
      {/* edit client policy before completed state */}
      <ContentRoute
        path="/informant-member/edit-client-for-policy/:policyId/:clientId"
        component={MainPolicyForm}
      />
      <ContentRoute
        path="/informant-member/list-informant-member"
        component={ListInformantMemberForInformantMember}
      />
      <ContentRoute
        path="/informant-member/add-informant-member"
        component={FormInformantMemberForInformantMember}
      />

      <ContentRoute
        path="/informant-member/edit-informant-member/:id"
        component={MainInformantForm}
      />

      <ContentRoute
        path="/informant-member/list-companies"
        component={CompanyList}
      />

      <ContentRoute
        path="/informant-member/add-company"
        component={CompanyForm}
      />
    </>
  );
};

//client
const ClientRoute = () => {
  return (
    <>
      <ContentRoute
        path="/client/list-signed-policy"
        component={ListClientSignedPolicy}
      />
    </>
  );
};

//super admin

const SuperAdminRoute = () => {
  return (
    <>
      <ContentRoute
        path="/super-admin/list-user"
        component={ListUserSuperAdmin}
      />
      <ContentRoute
        path="/super-admin/add-admin"
        component={AdminFormSuperAdmin}
      />
    </>
  );
};

export default function BasePage() {
  const currentRole = useSelector(
    ({ currentRole }) => currentRole,
    shallowEqual
  );
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {<Redirect exact from="/" to="/dashboard" />}
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/user-profile" component={UserProfile} />
        <ContentRoute
          path="/informant-member/client-for-policy/:policyId/:clientId"
          component={componentRoute(
            "informant_member",
            currentRole,
            MainPolicyForm
          )}
        />
        {/* admin route */}
        {currentRole === "admin" ? <AdminRoute /> : null}

        {/* informant member route */}
        {currentRole === "informant_member" ? <InformantRoute /> : null}

        {/* client route */}
        {currentRole === "client" ? <ClientRoute /> : null}

        {/* super admin route */}
        {currentRole === "super_admin" ? <SuperAdminRoute /> : null}

        <Redirect from="*" to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
