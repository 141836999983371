/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from "react";
import objectPath from "object-path";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { shallowEqual, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Brand } from "../brand/Brand";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuickUserToggler";
import AdminNav from "./navBar/admin.nav";
import InformantMemberNav from "./navBar/informant_member.nav";
import ClientNav from "./navBar/client.nav";
import SuperAdminNav from "./navBar/super_admin.nav";
import { AsideMenu } from "./aside-menu/AsideMenu";
import { KTUtil } from "./../../../_assets/js/components/util";
import KTLayoutAsideToggle from "./../../../_assets/js/layout/base/aside-toggle";
import { toAbsoluteUrl } from "../../../_helpers";

export function Aside() {
  const uiService = useHtmlClassService();
  const { currentRole, roles } = useSelector(
    ({ auth, currentRole }) => ({
      currentRole: currentRole,
      roles: auth.roles,
    }),
    shallowEqual
  );

  const layoutProps = useMemo(() => {
    return {
      asideClassesFromConfig: uiService.getClasses("aside", true),
      asideSecondaryDisplay: objectPath.get(
        uiService.config,
        "aside.secondary.display"
      ),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      extrasSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      extrasNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      extrasQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      extrasQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      extrasLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      extrasUserDisplay: objectPath.get(
        uiService.config,
        "extras.user.display"
      ),
    };
  }, [uiService]);

  const tabs = {
    tabId1: "kt_aside_tab_1",
    tabId2: "kt_aside_tab_2",
    tabId3: "kt_aside_tab_3",
    tabId4: "kt_aside_tab_4",
  };

  const [activeTab, setActiveTab] = useState("");

  const handleTabChange = (id) => {
    setActiveTab(id);
    if (id === "kt_aside_tab_2") {
      if (window.matchMedia("(max-width: 700px)").matches) {
        document.getElementById("kt_aside").style.width = "294px";
      }
      KTLayoutAsideToggle.getToggle().toggleOff();
    } else {
      if (window.matchMedia("(max-width: 700px)").matches) {
        document.getElementById("kt_aside").style.width = "70px";
      }
      KTLayoutAsideToggle.getToggle().toggleOn();
    }

    const asideWorkspace = KTUtil.find(
      document.getElementById("kt_aside"),
      ".aside-secondary .aside-workspace"
    );
    if (asideWorkspace) {
      KTUtil.scrollUpdate(asideWorkspace);
    }
  };

  return (
    <>
      <div
        id="kt_aside"
        className={`aside aside-left d-flex ${layoutProps.asideClassesFromConfig}`}
      >
        <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
          <Brand />
          <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull side__bar">
            {currentRole && currentRole === "admin" ? (
              <AdminNav
                layoutProps={layoutProps}
                handleTabChange={handleTabChange}
                tabs={tabs}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
              />
            ) : currentRole === "informant_member" ? (
              <InformantMemberNav
                layoutProps={layoutProps}
                handleTabChange={handleTabChange}
                tabs={tabs}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
              />
            ) : currentRole === "client" ? (
              <ClientNav
                layoutProps={layoutProps}
                handleTabChange={handleTabChange}
                tabs={tabs}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
              />
            ) : currentRole === "super_admin" ? (
              <SuperAdminNav
                layoutProps={layoutProps}
                handleTabChange={handleTabChange}
                tabs={tabs}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
              />
            ) : (
              ""
            )}
          </div>

          {/* begin::Footer */}
          <div className="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10">
            {/* begin::Aside Toggle */}
            {layoutProps.asideSecondaryDisplay &&
              layoutProps.asideSelfMinimizeToggle && (
                <>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="toggle-aside">
                        <FormattedMessage id="ASIDE_TOGGLE_ASIDE" />
                      </Tooltip>
                    }
                  >
                    <span
                      className="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm aside-toggle-active"
                      id="kt_aside_toggle"
                      style={{
                        display: activeTab !== tabs.tabId2 ? "none" : "grid",
                      }}
                    >
                      <i className="fa fa-angle-left" />
                    </span>
                  </OverlayTrigger>
                </>
              )}
            {/* end::Aside Toggle */}

            {roles && roles.length < 2
              ? ""
              : layoutProps.extrasQuickPanelDisplay && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="toggle-quick-panel">
                        <FormattedMessage id="ASIDE_SWITCH_ACCOUNT" />
                      </Tooltip>
                    }
                  >
                    <a
                      href="#"
                      className="btn btn-icon btn-clean btn-lg mb-1 position-relative"
                      id="kt_quick_panel_toggle"
                      data-placement="right"
                      data-container="body"
                      data-boundary="window"
                    >
                      <span className="svg-icon svg-icon-lg">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Layout/Layout-4-blocks.svg"
                          )}
                        />
                      </span>
                    </a>
                  </OverlayTrigger>
                )}

            {/* begin::Languages*/}
            {!layoutProps.extrasLanguagesDisplay && (
              <LanguageSelectorDropdown />
            )}
            {/* end::Languages */}

            {/* begin::User*/}
            {layoutProps.extrasUserDisplay && <QuickUserToggler />}
            {/* end::User */}
          </div>
          {/* end::Footer */}
        </div>
        {/* end::Primary */}
        {layoutProps.asideSecondaryDisplay && (
          <>
            {/* begin::Secondary */}
            <div className="aside-secondary d-flex flex-row-fluid">
              {/* begin::Workspace */}
              <div className="aside-workspace scroll scroll-push my-2">
                <div className="tab-content">
                  <AsideMenu isActive={activeTab === tabs.tabId2} />
                </div>
              </div>
              {/* end::Workspace */}
            </div>
            {/* end::Secondary */}
          </>
        )}
      </div>
      {/* end::Aside */}
    </>
  );
}
