/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import objectPath from "object-path";

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import OtpVerification from "./OtpVerification";
import FirstTimePasswordChange from "./FirstTimePasswordChange";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";

import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { shallowEqual, useSelector } from "react-redux";
import { useHtmlClassService } from "../../../../_metronic/layout/_core/MetronicLayout";
import { LanguageSelectorDropdown } from "../../../../_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown";

export function AuthPage() {
  const { isAuthorized, isPasswordSet, isToken } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user,
      isPasswordSet: auth.passwordToggle,
      isToken: auth.authToken,
    }),
    shallowEqual
  );

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      asideClassesFromConfig: uiService.getClasses("aside", true),
      extrasLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
    };
  }, [uiService]);
  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10  justify-content-center align-items-center"
            style={{
              backgroundImage: `url(${toAbsoluteUrl(
                "/media/image/banner10.png"
              )})`,
            }}
          >
            <div className="">
              <Link
                to="/"
                onClick={(event) => event.preventDefault()}
                className="pb-lg-0 pb-10"
              >
                <img
                  alt="Logo"
                  className="max-h-150px"
                  src={toAbsoluteUrl("/media/logo/LOGO_MUTUA_GENESI.png")}
                />
              </Link>
            </div>
          </div>

          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            <div className="text-right">
              {!layoutProps.extrasLanguagesDisplay && (
                <LanguageSelectorDropdown />
              )}
            </div>
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute
                  exact
                  path="/auth/reset-password/:token/:email"
                  component={ResetPassword}
                />
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute path="/auth/otp" component={OtpVerification} />
                <ContentRoute
                  path="/auth/set-password"
                  component={FirstTimePasswordChange}
                />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
              </Switch>
              {!isPasswordSet && isAuthorized && isToken ? (
                <Redirect to="/auth/set-password" />
              ) : (
                <Redirect to="/auth/login" />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
