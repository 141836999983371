import axios from "axios";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import * as notification from "../app/_redux/notification";

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      const lang = JSON.parse(localStorage.getItem("i18nConfig"));
      config.headers.language =
        lang && lang.selectedLang ? lang.selectedLang : "en";
      if (authToken) {
        config.headers.Authorization = `JWT ${authToken}`;
      }
      return config;
    },
    (err) => Promise.reject(err)
  );
  axios.interceptors.response.use(
    (res) => {
      if (res.data.toast === true) {
        store.dispatch(
          notification.actions.setErrorNotification({
            message: res.data.message,
            toggle: res.data.toast,
            type: res.data.response_type,
          })
        );
        setTimeout(() => {
          store.dispatch(
            notification.actions.setErrorNotification({
              message: "",
              toggle: false,
              type: "",
            })
          );
        }, 3000);
      }
      return res;
    },
    (e) => {
      console.log(e);
      if (e.response.status === 401) {
        store.dispatch(auth.actions.logout());
        window.location.reload();
      }
    }
  );
}

export const axiosPost = (url, data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}${url}`, data);
};

export const axiosGet = (url, data) => {
  return axios.get(`${process.env.REACT_APP_API_URL}${url}`, {
    params: data,
  });
};

export const axiosDelete = (url) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}${url}`);
};

export const axiosPatch = (url, data) => {
  return axios.patch(`${process.env.REACT_APP_API_URL}${url}`, data);
};
