import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import * as currentRole from "../app/_redux/currentRole";
import * as notification from "../app/_redux/notification";

export const rootReducer = persistReducer(
  {
    storage,
    key: "mutu-genesi",
    whitelist: ["auth", "currentRole"],
  },
  combineReducers({
    auth: auth.reducer,
    currentRole: currentRole.reducer,
    notification: notification.reducer,
  })
);

export function* rootSaga() {
  yield all([auth.saga()]);
}
