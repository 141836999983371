export const actionTypes = {
  setErrorNotification: "[setErrorNotification] Action",
};

const initialAuthState = {
  message: "",
  toggle: false,
  type: "",
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.setErrorNotification: {
      return action.payload;
    }
    default:
      return state;
  }
};

export const actions = {
  setErrorNotification: (notification) => ({
    type: actionTypes.setErrorNotification,
    payload: notification,
  }),
};
