import React from "react";
import { NavLink } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation } from "react-router";
import SVG from "react-inlinesvg";
import { FormattedMessage } from "react-intl";

import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

const ClientNav = (props) => {
  const location = useLocation();

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  // /client/list-signed-policy
  return (
    <ul className="list-unstyled flex-column" role="tablist">
      <li
        className={`nav-item mb-3 menu-item ${getMenuItemActive("/dashboard")}`}
        data-toggle="tooltip"
        data-placement="rigth"
        data-container="body"
        data-boundary="window"
        title="Dashboard"
        aria-haspopup="true"
      >
        <NavLink
          className="menu-link nav-link btn btn-icon btn-clean btn-lg"
          to="/dashboard"
          data-toggle="tab"
          id={`#${props.tabs.tabId1}`}
          role="tab"
          onClick={() => props.handleTabChange(props.tabs.tabId1)}
        >
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="latest-project">
                <FormattedMessage id="CLIENT_DASHBOARD" />
              </Tooltip>
            }
          >
            <span className="svg-icon svg-icon-lg">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")} />
            </span>
          </OverlayTrigger>
        </NavLink>
      </li>
      <li
        className={`nav-item mb-3 menu-item ${getMenuItemActive(
          "/client/list-signed-policy"
        )}`}
        data-toggle="tooltip"
        data-placement="rigth"
        data-container="body"
        data-boundary="window"
        title="Dashboard"
        aria-haspopup="true"
      >
        <NavLink
          className="menu-link nav-link btn btn-icon btn-clean btn-lg"
          to="/client/list-signed-policy"
          data-toggle="tab"
          id={`#${props.tabs.tabId3}`}
          role="tab"
          onClick={() => props.handleTabChange(props.tabs.tabId3)}
        >
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="latest-project">
                <FormattedMessage id="ASIDE_ADMIN_SUB_NAV_POLICY_SUBSCRIPTION" />
              </Tooltip>
            }
          >
            <span className="svg-icon svg-icon-lg">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Menu.svg")} />
            </span>
          </OverlayTrigger>
        </NavLink>
      </li>
    </ul>
  );
};

export default ClientNav;
