/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

export function BreadCrumbs({ items, title }) {
  const role = useSelector(({ currentRole }) => currentRole, shallowEqual);
  const user = useSelector(({ auth }) => auth.user, shallowEqual);

  if (!items || !items.length) {
    return "";
  }

  return (
    <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0">
      {/<\/?[a-z][\s\S]*>/i.test(title) !== true ? (
        <Link className="breadcrumb-item" to="/dashboard">
          {user && Object.keys(user).length
            ? user.name + " " + user.surname
            : ""}
        </Link>
      ) : (
        ""
      )}
      {items.map((item, index) => (
        <li className="breadcrumb-item" key={index}>
          <Link className="text-muted " to={{ pathname: item.pathname }}>
            {role && /<\/?[a-z][\s\S]*>/i.test(title) !== true ? (
              role === "informant_member" ? (
                <FormattedMessage id="ASIDE_INFORMANT_MEMBER_ROLE" />
              ) : role === "admin" ? (
                <FormattedMessage id="ASIDE_ADMIN_ROLE" />
              ) : role === "super_admin" ? (
                <FormattedMessage id="ASIDE_SUPER_ADMIN_ROLE" />
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </Link>
        </li>
      ))}
    </ul>
  );
}
