import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { connect, shallowEqual, useSelector } from "react-redux";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { setPassword } from "../_redux/authCrud";

const initialValues = {
  password: "",
  changepassword: "",
};

function FirstTimePasswordChange(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);

  const { user, isPasswordSet } = useSelector(
    ({ auth }) => ({
      user: auth.user,
      isPasswordSet: auth.passwordToggle,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (isPasswordSet) {
      props.history.push("/");
    }
  }, [isPasswordSet, props]);

  const FirstTimePasswordChangeSchema = Yup.object().shape({
    password: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          intl.formatMessage({
            id: "AUTH.VALIDATION.CONFIRM_PASSWORD_FIELD",
          })
        ),
      }),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: FirstTimePasswordChangeSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
      let data = {
        password: values.password,
        email: user.email,
      };

      setPassword(data)
        .then((res) => {
          disableLoading();
          setSubmitting(false);
          if (
            res.data.response_type === "success" &&
            res.data.data !== [] &&
            Object.keys(res.data.data).length > 0
          ) {
            props.setPasswordToggle({ passwordToggle: true });
            props.history.push("/dashboard");
          } else if (
            res.data.response_type === "error" &&
            res.data.data !== [] &&
            Object.keys(res.data.data).length > 0
          ) {
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.NEW_PASSWORD_CHANGE",
              })
            );
          } else {
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.PASSWORD_CHANGE",
              })
            );
          }
        })
        .catch(() => {
          setSubmitting(false);
          disableLoading();
        });
    },
  });

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.FIRST_PASSWORD_CHANGE.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          <FormattedMessage id="AUTH.FIRST_PASSWORD_CHANGE.TEXT" />
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={props.intl.messages["AUTH.INPUT.PASSWORD"]}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block text-danger">
                {formik.errors.password}
              </div>
            </div>
          ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Confirm Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={props.intl.messages["AUTH.INPUT.CONFIRM_PASSWORD"]}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "changepassword"
            )}`}
            name="changepassword"
            {...formik.getFieldProps("changepassword")}
          />
          {formik.touched.changepassword && formik.errors.changepassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block text-danger">
                {formik.errors.changepassword}
              </div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Password */}

        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>
              <FormattedMessage id="AUTH.FIRST_PASSWORD_CHANGE.BUTTON" />
            </span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(FirstTimePasswordChange));
