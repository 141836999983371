import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { otpVerification } from "../_redux/authCrud";

const initialValues = {
  otp: "",
};

function OtpVerification(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!props.location.state) {
      props.history.push("/auth/login");
    }
  }, [props.location.state, props]);

  const OtpSchema = Yup.object().shape({
    otp: Yup.string()
      .min(
        6,
        intl.formatMessage({
          id: "AUTH.VALIDATION.OTP.LENGTH",
        })
      )
      .max(
        6,
        intl.formatMessage({
          id: "AUTH.VALIDATION.OTP.LENGTH",
        })
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: OtpSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      let data = {
        otp: values.otp,
        email: props.location.state.email,
      };
      await otpVerification(data).then(({ data }) => {
        disableLoading();
        if (data.response_type !== "error") {
          if (data.data !== []) {
            props.otp({
              authToken: data.data[0].token,
              email: data.data[0].email,
              passwordToggle: data.data[0].is_password_change,
            });
            if (data.data[0].is_password_change === false) {
              props.history.push("/auth/set-password");
            } else {
              props.history.push("/dashboard");
            }
          }
        } else {
          if (data.message) {
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_OTP",
              })
            );
          }
        }
      });
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.OTP.TITLE" />
        </h3>
      </div>

      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          ""
        )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="OTP"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "otp"
            )}`}
            name="otp"
            {...formik.getFieldProps("otp")}
          />
          {formik.touched.otp && formik.errors.otp ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block text-danger">
                {formik.errors.otp}
              </div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>
              <FormattedMessage id="AUTH.OTP.BUTTON" />
            </span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(OtpVerification));
