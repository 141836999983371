import React from "react";
import { NavLink } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation } from "react-router";
import SVG from "react-inlinesvg";
import { FormattedMessage } from "react-intl";

import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

const InformantMemberNav = (props) => {
  const location = useLocation();

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  return (
    <div id="kt_header_menu" data-menu-vertical="1">
      <ul className="list-unstyled flex-column" role="tablist">
        <li
          className={`nav-item mb-3 menu-item ${getMenuItemActive(
            "/dashboard"
          )}`}
        >
          <NavLink
            className="menu-link nav-link btn btn-icon btn-clean btn-lg"
            to="/dashboard"
            data-toggle="tab"
            id={`#${props.tabs.tabId1}`}
            role="tab"
            onClick={() => props.handleTabChange(props.tabs.tabId1)}
          >
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="latest-project">
                  <FormattedMessage id="INFORMANT_DASHBOARD" />
                </Tooltip>
              }
            >
              <div>
                <span className="svg-icon svg-icon-lg">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")} />
                </span>
                <span className="menu-text d-none">
                  <FormattedMessage id="INFORMANT_DASHBOARD" />
                </span>
              </div>
            </OverlayTrigger>
          </NavLink>
        </li>

        <li className={`nav-item mb-3 menu-item`}>
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="latest-project">
                <FormattedMessage id="ASIDE_ADMIN_NAV_POLICY" />
              </Tooltip>
            }
          >
            <a
              href="#"
              className={`menu-link nav-link btn btn-icon btn-clean btn-lg ${props.activeTab ===
                props.tabs.tabId2 && "active"}`}
              data-toggle="tab"
              id={`#${props.tabs.tabId2}`}
              role="tab"
              onClick={() => props.handleTabChange(props.tabs.tabId2)}
            >
              <span className="svg-icon svg-icon-lg">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Menu.svg")} />
              </span>
            </a>
          </OverlayTrigger>
        </li>

        <li
          className={`nav-item mb-3 menu-item ${getMenuItemActive(
            "/informant-member/list-companies"
          )}`}
        >
          <NavLink
            className="menu-link nav-link btn btn-icon btn-clean btn-lg"
            to="/informant-member/list-companies"
            data-toggle="tab"
            id={`#${props.tabs.tabId3}`}
            role="tab"
            onClick={() => props.handleTabChange(props.tabs.tabId3)}
          >
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="latest-project">
                  <FormattedMessage id="TABLE_COMPANY_TITLE" />
                </Tooltip>
              }
            >
              <div>
                <span className="svg-icon svg-icon-lg">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}
                  />
                </span>
                <span className="menu-text d-none">
                  <FormattedMessage id="TABLE_COMPANY_TITLE" />
                </span>
              </div>
            </OverlayTrigger>
          </NavLink>
        </li>

        <li
          className={`nav-item mb-3 menu-item ${getMenuItemActive(
            "/informant-member/list-client"
          )}`}
        >
          <NavLink
            className="menu-link nav-link btn btn-icon btn-clean btn-lg"
            to="/informant-member/list-informant-member"
            data-toggle="tab"
            id={`#${props.tabs.tabId4}`}
            role="tab"
            onClick={() => props.handleTabChange(props.tabs.tabId4)}
          >
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="latest-project">
                  <FormattedMessage id="ASIDE_ADMIN_NAV_INFORMANT" />
                </Tooltip>
              }
            >
              <div>
                <span className="svg-icon svg-icon-lg">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Add-user.svg"
                    )}
                  />
                </span>
                <span className="menu-text d-none">
                  <FormattedMessage id="ASIDE_ADMIN_NAV_INFORMANT" />
                </span>
              </div>
            </OverlayTrigger>
          </NavLink>
        </li>

        {/* start insert and edit component */}
        <li
          className={`nav-item mb-3 menu-item d-none ${getMenuItemActive(
            "/informant-member/add-company"
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/informant-member/add-company">
            <span className="menu-text d-none">
              <FormattedMessage id="ASIDE_NAV_INFORMANT_COMPANY_TITLE" />
            </span>
          </NavLink>
        </li>

        <li
          className={`nav-item mb-3 menu-item d-none ${getMenuItemActive(
            "/informant-member/edit-informant-member/:id"
          )}`}
          aria-haspopup="true"
        >
          <NavLink
            className="menu-link"
            to={`/informant-member/edit-informant-member/`}
          >
            <span className="menu-text d-none">
              <FormattedMessage id="ASIDE_NAV_INFORMANT_EDIT_INFORMANT_TITLE" />
            </span>
          </NavLink>
        </li>

        <li
          className={`nav-item mb-3 menu-item d-none ${getMenuItemActive(
            "/informant-member/add-informant-member"
          )}`}
          aria-haspopup="true"
        >
          <NavLink
            className="menu-link"
            to="/informant-member/add-informant-member"
          >
            <span className="menu-text d-none">
              <FormattedMessage id="ASIDE_NAV_INFORMANT_ADD_INFORMANT_TITLE" />
            </span>
          </NavLink>
        </li>

        <li
          className={`nav-item mb-3 menu-item d-none ${getMenuItemActive(
            "/informant-member/edit-client-for-policy/"
          )}`}
          aria-haspopup="true"
        >
          <NavLink
            className="menu-link"
            to={`/informant-member/edit-client-for-policy/`}
          >
            <span className="menu-text d-none">
              <FormattedMessage id="ASIDE_NAV_INFORMANT_EDIT_POLICY_SUBSCRIPTION_TITLE" />
            </span>
          </NavLink>
        </li>

        <li
          className={`nav-item mb-3 menu-item d-none ${getMenuItemActive(
            "/informant-member/subscribe-policy"
          )}`}
          aria-haspopup="true"
        >
          <NavLink
            className="menu-link"
            to="/informant-member/subscribe-policy"
          >
            <span className="menu-text d-none">
              <FormattedMessage id="ASIDE_NAV_INFORMANT_ADD_POLICY_SUBSCRIPTION_TITLE" />
            </span>
          </NavLink>
        </li>

        <li
          className={`nav-item mb-3 menu-item d-none ${getMenuItemActive(
            "/informant-member/view-policy/"
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/informant-member/view-policy/">
            <span className="menu-text d-none">
              <FormattedMessage id="ASIDE_ADMIN_SUB_NAV_VIEW_POLICY_TITLE" />
            </span>
          </NavLink>
        </li>
        {/* end insert and edit component */}

        <li
          className={`menu-item d-none ${getMenuItemActive(
            "/user-profile",
            false
          )}`}
        >
          <NavLink className="menu-link" to="/user-profile">
            <span className="menu-text">
              <FormattedMessage id="USER.PROFILE.TITLE" />
            </span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default InformantMemberNav;
