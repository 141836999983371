/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { FormattedMessage } from "react-intl";

import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuListInformant({ layoutProps }) {
  const location = useLocation();

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`nav-item mb-3 menu-item ${getMenuItemActive(
            "/informant-member/list-policy"
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/informant-member/list-policy">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Menu.svg")} />
            </span>
            <span className="menu-text">
              <FormattedMessage id="ASIDE_ADMIN_SUB_NAV_POLICY" />
            </span>
          </NavLink>
        </li>
        <li
          className={`nav-item mb-3 menu-item ${getMenuItemActive(
            "/informant-member/list-sign-policy"
          )}`}
          aria-haspopup="true"
        >
          <NavLink
            className="menu-link"
            to="/informant-member/list-sign-policy"
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/stock-home.svg"
                )}
              />
            </span>
            <span className="menu-text">
              <FormattedMessage id="ASIDE_ADMIN_SUB_NAV_POLICY_SUBSCRIPTION" />
            </span>
          </NavLink>
        </li>
      </ul>
    </>
  );
}
