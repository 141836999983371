/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { toAbsoluteUrl } from "../../../../_helpers";
import { getUserProfile } from "../../../../../app/services/user_profile/user_profile";
// import { shallowEqual, useSelector } from "react-redux";

export function QuickUser() {
  const history = useHistory();

  // const user = useSelector((state) => state.auth, shallowEqual);
  const [user, setUser] = useState([]);

  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUser = async () => {
    const res = await getUserProfile();
    if (res !== []) {
      setUser(res.data.data);
    }
  };

  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-left offcanvas p-10">
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">
          <FormattedMessage id="ASIDE_USER_PROFILE" />
        </h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="fa fa-times" />
        </a>
      </div>

      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5">
          <div className="symbol symbol-100 mr-5">
            <div
              className="symbol-label"
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  "/media/svg/icons/General/User.svg"
                )})`,
              }}
            />
          </div>
          <div className="d-flex flex-column">
            <a
              href="#"
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {user.name} {user.surname}
            </a>
            <div className="text-muted mt-1">{user.occupation}</div>
            <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Mail-notification.svg"
                        )}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    {user.email}
                  </span>
                </span>
              </a>
            </div>
            {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
              Sign Out
            </Link> */}
            <button
              className="btn btn-primary btn-bold"
              onClick={logoutClick}
            >
              <FormattedMessage id="AUTH.LOGOUT.BUTTON" />
            </button>
          </div>
        </div>
        <div className="separator separator-dashed mt-8 mb-5" />

        <div className="navi navi-spacer-x-0 p-0">
          <Link to="/user-profile" className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-success">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/Notification2.svg"
                      )}
                    ></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">
                  <FormattedMessage id="ASIDE_MY_PROFILE" />
                </div>
                <div className="text-muted">
                  <FormattedMessage id="ASIDE_MY_PROFILE_TEXT" />{" "}
                  <span className="label label-light-danger label-inline font-weight-bold">
                    <FormattedMessage id="ASIDE_MY_PROFILE_UPDATE" />
                  </span>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="separator separator-dashed my-7"></div>
      </div>
    </div>
  );
}
