import React from "react";
import { connect } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import * as role from "../../../../../app/_redux/currentRole";

function QuickPanelMain(props) {
  const history = useHistory();
  const roles = useSelector(({ auth }) => auth.roles, shallowEqual);

  const changeRole = (role, event) => {
    event.preventDefault();
    document.getElementById("kt_quick_panel_close").click();
    props.setCurrentRole(role);
    history.push(`/dashboard`);
  };

  return (
    <div id="kt_quick_panel" className="offcanvas offcanvas-left pt-5 pb-10">
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5 pl-5 pr-5">
        <h3 className="font-weight-bold m-0">
          <FormattedMessage id="ASIDE_SWITCH_ACCOUNT" />
        </h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_panel_close"
        >
          <i className="fa fa-times" />
        </a>
      </div>

      <div className="offcanvas-content pr-5 mr-n5">
        {roles &&
          roles.map((e) => (
            <div className="row py-2 m-0 mx-5" key={e}>
              <a
                href="/"
                className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5"
                onClick={(event) => changeRole(e, event)}
              >
                <span className="d-block font-weight-bold font-size-h6 mt-2">
                  {e === "informant_member" ? (
                    <FormattedMessage id="ASIDE_INFORMANT_MEMBER_ROLE" />
                  ) : (
                    <FormattedMessage id="ASIDE_ADMIN_ROLE" />
                  )}
                </span>
              </a>
            </div>
          ))}
      </div>
    </div>
  );
}

export default connect(null, role.actions)(QuickPanelMain);
