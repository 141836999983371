import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";

import * as role from "../../../_redux/currentRole";

export const actionTypes = {
  Login: "[Login] Action",
  Otp: "[Otp] Action",
  Logout: "[Logout] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  SetPasswordToggle: "[SetPasswordToggle] Action",
  RolesLoaded: "[RolesLoaded] Auth API",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  passwordToggle: false,
  roles: [],
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.Login: {
      return initialAuthState;
    }

    case actionTypes.Otp: {
      const { authToken } = action.payload;
      return {
        authToken: authToken.authToken,
        user: authToken.email,
        passwordToggle: authToken.passwordToggle,
      };
    }

    case actionTypes.SetPassword: {
      const { authToken } = action.payload;
      return { authToken, user: authToken.email };
    }

    case actionTypes.Logout: {
      return initialAuthState;
    }

    case actionTypes.UserLoaded: {
      const { user } = action.payload;
      return { ...state, user };
    }

    case actionTypes.SetUser: {
      const { user } = action.payload;
      return { ...state, user };
    }

    case actionTypes.SetPasswordToggle: {
      return { ...state, ...action.payload };
    }

    case actionTypes.RolesLoaded: {
      const { roles } = action.payload;
      return { ...state, roles };
    }

    default:
      return state;
  }
};

export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  otp: (authToken) => ({ type: actionTypes.Otp, payload: { authToken } }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  fulfillRoles: (roles) => ({
    type: actionTypes.RolesLoaded,
    payload: { roles },
  }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  setPasswordToggle: (passwordToggle) => ({
    type: actionTypes.SetPasswordToggle,
    payload: passwordToggle,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {});

  yield takeLatest(actionTypes.Otp, function* otpSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const user = yield getUserByToken();

    yield put(actions.fulfillUser(user.data.data));
    yield put(actions.fulfillRoles(user.data.data.roles));
    yield put(role.actions.setCurrentRole(user.data.data.roles[0]));
  });
}
