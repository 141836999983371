export const actionTypes = {
  setCurrentRole: "[setCurrentRole] Action",
};

const initialAuthState = "client";

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.setCurrentRole: {
      return action.payload;
    }
    default:
      return state;
  }
};

export const actions = {
  setCurrentRole: (role) => ({
    type: actionTypes.setCurrentRole,
    payload: role,
  }),
};
